// Translated
// Migrated
<template lang="pug">
.slide-carousel-container.position-relative.z-0
  Swiper.h-100(
    v-bind="swiperPropsValues"
    dots-type="light"
    dots-align="bottom"
    arrows-type="circular"
    loop
  )
    component.position-relative.overflow-hidden.h-100.d-block(
      :is="slide.component"
      v-for="(slide, index) in getSlides"
      :key="index"
      :to="slide.link ? slide.link.href : null"
    )
      ResponsiveImage.carousel-image.h-100(
        :image="slide.image"
        :alt="slide?.description || slide?.link?.text || $t('siteTitleBrand')",
        cover
      )
      .slide-carousel.position-absolute.w-100.h-100.z-3.top-0.d-flex.flex-column.justify-content-center.align-items-center.px-5
        .slide-carousel-title.text-white.text-center.h-auto.font-weight-bold(
          v-if="slide.title", :class="titleClass"
        ) {{ slide.title }}

        .slide-carousel-description.d-inline.text-white.text-shadow.text-center.font-weight-normal.text-break-pre-line(
          v-if="slide.description"
        ) {{ slide.description }}

        span.slide-carousel-link.h4.mt-2.text-white.text-shadow.font-weight-normal(
          v-if="slide.link?.text"
        )
          span(:data-i18n="slide.link.text") {{ $t(slide.link.text) }}
          fa.ml-3.position-absolute(icon="arrow-right-long")
</template>

<script setup>
import swiperProps from '@packages/swiper/constants/props.js'

import { NuxtLink } from '#components'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const { slides, titleClass, dotsType, maxSlides, ...swiperPropsValuesRaw } = defineProps({
  ...swiperProps,
  /**
   * @prop {Array.<{
   *  image: String
   *  title?: String
   *  description?: String2
   *  link?: {
   *    href: String
   *    text?: String
   *  }
   * }>} slides
   */
  slides: {
    type: Array,
    required: true,
    default: () => []
  },

  titleClass: {
    type: String,
    default: 'display-4 font-weight-bold'
  }
})

const swiperPropsValues = ref(swiperPropsValuesRaw)

const getSlides = computed(() => {
  if ((slides || []).length === 0) {
    return [{
      image: null
    }]
  }
  return slides
    .filter(Boolean)
    .map(slide => ({
      ...slide,
      component: slide.link ? NuxtLink : 'div'
    }))
    .slice(0, maxSlides)
})
</script>

<style lang="scss">
.slide-carousel-container {
  .slide-carousel {
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.57);

    &-title {
      letter-spacing: 0.5px;
      font-size: 3.125rem;

      @media (max-width: $md) {
        font-size: 2.3rem;
        line-height: 42px;
      }

      @media (max-width: $sm) {
        font-size: 2.1rem;
        line-height: 42px;
      }
    }

    &-description {
      font-size: 1.8rem;
      height: auto !important;
      letter-spacing: .3px;
      line-height: 37px;

      @media (max-width: $md) {
        font-size: 1.8rem;
        line-height: 36px;
      }

      @media (max-width: $sm) {
        font-size: 1.2rem;
        line-height: 26px;
      }
    }

    &-link {
      @media (max-width: $sm) {
        font-size: 1.3rem;
      }
    }
  }
}
</style>
